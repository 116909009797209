<template lang="">
  <BAlert
    show
    variant="danger"
    class="px-2 py-1 font-weight-bolder"
  >
    Anh/Chị nghiêm túc không thực hiện giữ chỗ khống và huỷ các Booking nếu không có khách bay để tránh ảnh hưởng đến uy tín và toàn bộ mạng bán của Bảo Gia Trần. Chúng tôi sẽ chuyển tiếp toàn bộ công văn xử phạt từ các Hãng (nếu có).
  </BAlert>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
}
</script>
<style lang="">

</style>
